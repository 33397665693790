import { css } from "aphrodite";
import PropTypes from "prop-types";
import { memo, useCallback, useRef, useMemo } from "react";
import scrollToComponent from "react-scroll-to-component-ssr";

import StandardButton from "components/Buttons/StandardButton";
import EntityNavigateSearch from "components/Search/EntityNavigateSearch";

import stageStyles from "./stageStyles";

import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";

const autoCompleteStyles = {
  searchContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    margin: 0,
  },
};

const searchStyles = {
  icon: {
    fontSize: "1.2em",
  },
  search: {
    border: "1px solid #DADADA",
  },
  input: {
    padding: ".8em 3em .7em 1.6em",
    height: "2.8rem",
  },
};

const addButtonStyles = {
  button: {
    marginTop: ".5rem",
    textDecoration: "underline",
  },
};

const analyticsVariables = {
  action: "creatorSearch",
  context: "prompt",
  componentContext: "SearchStage",
};

const baseStyles = {
  ...stageStyles,
  addButton: {
    height: "2.8rem",
    minWidth: "2.8rem",
    marginLeft: ".5rem",
    marginTop: 0,
  },
};

const buttonStyles = {
  button: {
    border: "none",
    textDecoration: "underline",
    marginTop: ".75rem",
    ...gStyles.fontRegular,
    fontSize: ".9rem",
    lineHeight: "1.4rem",
  },
};

const SEARCH_TYPES = ["creator"];

const SearchStage = (props) => {
  const {
    entity_type,
    introText,
    mobile,
    setStage,
    setSelectedCreator,
    setLastSearchTerm,
    onClose,
  } = props;
  const { styles } = useStyles(baseStyles, props);
  const searchInput = useRef(null);

  const resultClickOptions = useMemo(
    () => ({
      creator: (selected) => {
        setStage("addSelectedCreatorCredit");
        setSelectedCreator(selected);
      },
    }),
    [setStage, setSelectedCreator]
  );

  const handleSearchFocus = useCallback(
    () =>
      scrollToComponent(searchInput.current, {
        align: "top",
        offset: -5,
      }),
    []
  );

  const handleTermChange = useCallback(
    (lastTerm) => setLastSearchTerm(lastTerm),
    [setLastSearchTerm]
  );

  const noResultsMessage = (
    <div className={css(styles.noResultsMessage)}>
      No creators by that name were found.{" "}
      <StandardButton
        variation="transparent"
        label="Add them?"
        flat
        link
        to="/creators/add"
        className={css(styles.link)}
        customStyles={addButtonStyles}
      />
    </div>
  );

  return (
    <div className={css(styles.stageContainer)}>
      <h3 className={css(styles.stageName)}>Search For A Creator</h3>
      <p className={css(styles.description)}>
        {introText ||
          (mobile
            ? `Help us out by adding the creators of this ${entity_type} below`
            : `We don't know anything about the creators of this ${entity_type}. You can help us out by adding them below.`)}
      </p>
      <div className={css(styles.container)}>
        <div className={css(styles.search)}>
          <EntityNavigateSearch
            analyticsVariables={analyticsVariables}
            types={SEARCH_TYPES}
            searchKey="episode_creator_banner_search"
            placeholder={
              mobile ? "Search creators..." : "Search for a creator to add..."
            }
            noResultsMessage={noResultsMessage}
            searchMessage="Searching creators..."
            autoCompleteStyles={autoCompleteStyles}
            searchStyles={searchStyles}
            ref={searchInput}
            onFocus={handleSearchFocus}
            onResultClickOptions={resultClickOptions}
            onTermChange={handleTermChange}
            noFloatingResults
            hideHeading
            showViewLink
            white
            hideViewAll
          />
        </div>
        <div className={css(styles.addButton)}>
          <StandardButton
            onClick={onClose}
            label={"Add A New Creator"}
            to="/creators/add"
            title="Add New Creator"
            target="_blank"
            anchor
            variation="clear"
            customStyles={buttonStyles}
          />
        </div>
      </div>
    </div>
  );
};

SearchStage.propTypes = {
  setStage: PropTypes.func.isRequired,
  setSelectedCreator: PropTypes.func.isRequired,
  setLastSearchTerm: PropTypes.func.isRequired,
  entity_type: PropTypes.string,
  introText: PropTypes.node,
  mobile: PropTypes.bool,
};

SearchStage.defaultProps = {
  entity_type: "podcast",
  introText: null,
  mobile: false,
};

export default memo(SearchStage);
