import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { memo, useCallback } from "react";

import stageStyles from "./stageStyles";

import getCreatorEditUrl from "utils/entity/getCreatorEditUrl";
import eventIsFieldTrigger from "utils/misc/eventIsFieldTrigger";

import { useStyles } from "hooks/useStyles";

const LastOptionsStage = (props) => {
  const {
    onRequestClose,
    creator,
    setStage,
    setLastSearchTerm,
    setSavedCreatorId,
    addAnotherRoleTo,
  } = props;
  const { styles } = useStyles(stageStyles, props);

  const handleAddAnotherGuest = useCallback(
    (e) => {
      e.preventDefault();
      setSavedCreatorId(null);
      setStage(null);
      setLastSearchTerm("");
    },
    [setSavedCreatorId, setStage, setLastSearchTerm]
  );

  return (
    <div className={css(styles.stageContainer)}>
      <div className={css(styles.savedSucessMessage)}>
        Thanks for adding a credit for this episode. Until its been approved by
        our moderators the credit
        {" won't be publicly viewable."}
      </div>
      <h3 className={css(styles.stageName)}>What Next?</h3>
      <div className={css(styles.optionContainer)}>
        <div className={css(styles.optionTitle)}>
          <a
            data-testid="addRole"
            className={css(styles.optionLink)}
            href={addAnotherRoleTo}
            onClick={onRequestClose}
          >
            Add another role
          </a>{" "}
          {`for ${creator.get("name")} on this episode`}
        </div>
      </div>
      <div className={css(styles.optionDivider)} />
      <div className={css(styles.optionContainer)}>
        <div className={css(styles.optionTitle)}>
          <a
            data-testid="addCredit"
            className={css(styles.optionLink)}
            href="#"
            onClick={handleAddAnotherGuest}
            onKeyDown={(e) => {
              eventIsFieldTrigger(e) && e.preventDefault();
              eventIsFieldTrigger(e) && handleAddAnotherGuest(e);
            }}
          >
            Add another Guest Credit
          </a>{" "}
          to this episode
        </div>
      </div>
      <div className={css(styles.optionDivider)} />
      <div className={css(styles.optionContainer)}>
        <div className={css(styles.optionTitle)}>
          <a
            data-testid="addInfo"
            className={css(styles.optionLink)}
            href={getCreatorEditUrl(creator)}
            onClick={onRequestClose}
          >
            Add additional info
          </a>{" "}
          {`to ${creator.get("name")}'s profile`}
        </div>
      </div>
    </div>
  );
};

LastOptionsStage.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
  creator: PropTypes.instanceOf(Map).isRequired,
  setStage: PropTypes.func.isRequired,
  setLastSearchTerm: PropTypes.func.isRequired,
  setSavedCreatorId: PropTypes.func.isRequired,
  addAnotherRoleTo: PropTypes.string.isRequired,
};

export default memo(LastOptionsStage);
