import { fromJS, Map } from "immutable";
import PropTypes from "prop-types";
import { memo, useCallback, useMemo, useState } from "react";
import { saveEntityCreditPromise } from "routines/creator";

import GenericModal from "components/Common/GenericModal";

import ExistingCreatorAddCreditStage from "./EpisodeCreatorsPrompt/ExistingCreatorAddCreditStage";
import LastOptionsStage from "./EpisodeCreatorsPrompt/LastOptionsStage";
import SearchStage from "./EpisodeCreatorsPrompt/SearchStage";

import { selectSpecificCreator } from "selectors/creator";
import { getCreatorSlugByRawData } from "utils/entity/getCreatorSlug";
import { getCreatorEditSelectEpisodesUrlByCreatorSlug } from "utils/url/creatorUrls";

import useReduxState from "hooks/useReduxState";
import useRoutinePromises from "hooks/useRoutinePromises";
import useWindowSize from "hooks/useWindowSize";

const modalStyles = {
  closeButton: {
    top: 13,
    fontSize: ".9rem",
  },
};

const EpisodeCreatorsPrompt = (props) => {
  const { entity_type, title, entity, introText, onRequestClose, onSuccess } =
    props;
  const { isWindowSizeOrLess } = useWindowSize();
  const mobile = isWindowSizeOrLess("tiny");

  const [stage, setStage] = useState("search");
  const [saving, setSaving] = useState(false);
  const [savedCreatorId, setSavedCreatorId] = useState(null);
  const [savingError, setSavingError] = useState(null);
  const [selectedCreator, setSelectedCreator] = useState(null);
  const [lastSearchTerm, setLastSearchTerm] = useState("");

  const { saveEntityCredit } = useRoutinePromises({
    saveEntityCredit: saveEntityCreditPromise,
  });
  const savedCreator = useReduxState(
    (state) => selectSpecificCreator(state, savedCreatorId),
    [savedCreatorId]
  );

  const creator = savedCreator || selectedCreator;

  const existingCreatorSlug = getCreatorSlugByRawData(
    selectedCreator?.get("name"),
    selectedCreator?.get("id")
  );

  const addAnotherRoleTo = useMemo(
    () =>
      creator &&
      getCreatorEditSelectEpisodesUrlByCreatorSlug(
        existingCreatorSlug,
        entity.get("podcast_id"),
        [entity.get("id")]
      ),
    [creator, entity, existingCreatorSlug]
  );

  const handleSaveGuestAppearance = useCallback(
    (existingCreator = false) =>
      () => {
        setSaving(true);

        saveEntityCredit({
          creator_id: creator.get("id"),
          credits: fromJS([
            {
              entity_type: "podcast",
              entity_id: entity.get("podcast_id"),
              roles: [
                {
                  role: { code: "guest" },
                  episode_select_type: "selected",
                  episodeIds: [entity.get("id")],
                  ongoing: false,
                },
              ],
            },
          ]),
        })
          .then(() => {
            setStage(
              existingCreator ? "existingCreatorOptions" : "newCreatorOptions"
            );
            setSaving(false);
            setSavingError(null);

            if (onSuccess) {
              onSuccess();
            }
          })
          .catch(() => {
            setSaving(false);
            setSavingError(
              "Sorry we're having trouble saving this credit, please give it another go"
            );
          });
      },
    [saveEntityCredit, creator, entity, onSuccess]
  );

  const stageProps = {
    addAnotherRoleTo,
    onRequestClose,
    entity,
    creator,
    setStage,
    setSelectedCreator,
    setLastSearchTerm,
    setSavingError,
    setSaving,
    setSavedCreatorId,
    entity_type,
    introText,
    mobile,
    saving,
    savingError,
    lastSearchTerm,
    onSaveExistingGuestAppearance: handleSaveGuestAppearance(true),
  };

  const renderContent = (modalProps) => {
    if (stage === "newCreatorOptions" || stage === "existingCreatorOptions") {
      return <LastOptionsStage {...stageProps} />;
    }
    if (selectedCreator && stage === "addSelectedCreatorCredit") {
      return <ExistingCreatorAddCreditStage {...stageProps} />;
    }

    return <SearchStage {...stageProps} onClose={modalProps.onClose} />;
  };

  return (
    <GenericModal
      title={title || "No Creators"}
      renderContent={renderContent}
      styles={modalStyles}
      disableClose={saving}
      onClose={onRequestClose}
      smallTitle
    />
  );
};

EpisodeCreatorsPrompt.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  entity_type: PropTypes.string,
  entity: PropTypes.instanceOf(Map),
  introText: PropTypes.node,
  title: PropTypes.node,
};

EpisodeCreatorsPrompt.defaultProps = {
  onSuccess: null,
  entity_type: "podcast",
  entity: Map({}),
  introText: null,
  title: null,
};

export default memo(EpisodeCreatorsPrompt);
