import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

export default {
  stageContainer: {
    display: "flex",
    flexDirection: "column",
  },
  stageName: {
    ...gStyles.fontBold,
    color: colours.black,
    textAlign: "center",
    margin: "0 2rem 0",
    fontSize: "1.2rem",
  },
  description: {
    ...gStyles.fontRegular,
    color: colours.black,
    fontSize: ".9rem",
    lineHeight: "1.4rem",
    textAlign: "center",
    width: "100%",
    marginBottom: "1.5rem",
  },
  container: {
    display: "flex",
    flexDirection: "column",

    [ScreenSizes.smAndAbove]: {
      flexDirection: "column",
    },
  },
  search: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    margin: "auto",
  },
  addButton: {
    marginTop: ".5rem",

    [ScreenSizes.smAndAbove]: {
      height: "2.8rem",
      minWidth: "2.8rem",
      marginLeft: ".5rem",
      marginTop: 0,
    },
  },
  savingError: {
    ...gStyles.fontMedium,
    padding: ".5rem 0",
    color: colours.negative,
    maxWidth: "20rem",
    width: "100%",
    textAlign: "center",
    margin: "0 auto",
    lineHeight: "1.1rem",
    fontSize: ".813rem",
  },
  addDifferentRoleLink: {
    ...gStyles.fontSemiBold,
    color: colours.oldSecondary,
    fontSize: ".813rem",
    paddingBottom: "1rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textDecoration: "underline",
  },
  buttonRow: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",

    [ScreenSizes.mdAndAbove]: {
      flexDirection: "row",
    },
  },
  buttonContainer: {
    width: "100%",
    marginTop: ".5rem",

    [ScreenSizes.mdAndAbove]: {
      maxWidth: "12rem",
    },
  },
  buttonDivider: {
    width: ".5rem",
    height: "100%",
  },
  savedSucessMessage: {
    ...gStyles.fontSemiBold,
    fontSize: ".9rem",
    lineHeight: "1.5rem",
    color: "#BBB",
    textAlign: "center",
    marginTop: "1.2rem",
    marginBottom: ".5rem",
  },
  optionContainer: {
    margin: "1.25rem auto .25rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  optionTitle: {
    ...gStyles.avalonMedium,
    color: "#555",
    fontSize: ".9rem",
    lineHeight: "1.3rem",
    marginBottom: ".2rem",
  },
  optionLink: {
    ...gStyles.fontSemiBold,
    color: colours.primary,
  },
  optionButton: {
    maxWidth: "12rem",
    width: "100%",
    textDecoration: "underline",
  },
  optionDivider: {
    width: "6rem",
    height: "2px",
    margin: "1rem auto 0",
    backgroundColor: "#EEE",
  },
  noResultsMessage: {
    lineHeight: "1.4rem",
  },
  link: {
    ...gStyles.fontBold,
    cursor: "pointer",
    color: colours.primary,
  },
};
