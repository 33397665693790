import { createRoutine, promisifyRoutine } from "redux-saga-routines";

export const saveBio = createRoutine("CREATOR_SAVE_BIO");
export const saveBioPromise = promisifyRoutine(saveBio);

export const loadSpecificCreator = createRoutine("LOAD_SPECIFIC_CREATOR");
export const loadSpecificCreatorPromise = promisifyRoutine(loadSpecificCreator);

export const saveEntityCredit = createRoutine("SAVE_ENTITY_CREDIT");
export const saveEntityCreditPromise = promisifyRoutine(saveEntityCredit);

export const deleteEntityCredit = createRoutine("DELETE_ENTITY_CREDIT");
export const deleteEntityCreditPromise = promisifyRoutine(deleteEntityCredit);
export const getTwitterDetails = createRoutine("GET_TWITTER_DETAILS");
export const getTwitterDetailsPromise = promisifyRoutine(getTwitterDetails);

export const deleteCreditSubmission = createRoutine("DELETE_CREDIT_SUBMISSION");
export const deleteCreditSubmissionPromise = promisifyRoutine(
  deleteCreditSubmission
);

export const approveCreditSubmission = createRoutine("APPROVE_CREDIT");
export const approveCreditSubmissionPromise = promisifyRoutine(
  approveCreditSubmission
);

export const rejectCreditSubmission = createRoutine("REJECT_CREDIT");
export const rejectCreditSubmissionPromise = promisifyRoutine(
  rejectCreditSubmission
);

export const loadCreditsForGroup = createRoutine("LOAD_CREDITS_FOR_GROUP");
export const loadCreditsForGroupPromise = promisifyRoutine(loadCreditsForGroup);

export const rejectSuggestedCredits = createRoutine("REJECT_SUGGESTED_CREDITS");
export const rejectSuggestedCreditsPromise = promisifyRoutine(
  rejectSuggestedCredits
);

export const loadCreatorRoles = createRoutine("LOAD_CREATOR_ROLES");
export const loadCreatorRolesPromise = promisifyRoutine(loadCreatorRoles);

export const loadListOfCurrentCreditsOfPodcast = createRoutine(
  "LOAD_CURRENT_CREDITS_OF_PODCAST"
);
export const loadListOfCurrentCreditsOfPodcastPromise = promisifyRoutine(
  loadListOfCurrentCreditsOfPodcast
);

export const loadListOfRecurringCredits = createRoutine(
  "LOAD_RECURRING_CREDITS"
);
export const loadListOfRecurringCreditsPromise = promisifyRoutine(
  loadListOfRecurringCredits
);

export const submitCredit = createRoutine("CREDITS_SUBMIT_REQUEST");

export const submitSuggestedGuestCredits = createRoutine(
  "CREDITS_SUBMIT_SUGGESTED_GUEST_CREDITS_REQUEST"
);
export const submitSuggestedGuestCreditsPromise = promisifyRoutine(
  submitSuggestedGuestCredits
);

export const getCreatorSubmission = createRoutine("GET_CREATOR_SUBMISSION");
export const getCreatorSubmissionPromise =
  promisifyRoutine(getCreatorSubmission);
