import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { memo, useCallback, useMemo, useRef } from "react";
import scrollToComponent from "react-scroll-to-component-ssr";

import StandardButton from "components/Buttons/StandardButton";
import GenericModal from "components/Common/GenericModal";
import EntityNavigateSearch from "components/Search/EntityNavigateSearch";

import getCreatorSlug from "utils/entity/getCreatorSlug";
import { getCreatorEditSelectEpisodesUrlByCreatorSlug } from "utils/url/creatorUrls";

import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  container: {
    display: "flex",
    flexDirection: "column",

    [ScreenSizes.smAndAbove]: {
      flexDirection: "column",
    },
  },
  search: {
    display: "flex",
    flex: 1,
  },
  addButton: {
    marginTop: ".5rem",

    [ScreenSizes.smAndAbove]: {
      height: "2.8rem",
      minWidth: "2.8rem",
      marginLeft: ".5rem",
      marginTop: 0,
      display: "flex",
      justifyContent: "center",
    },
  },
  stageName: {
    ...gStyles.fontBold,
    color: colours.black,
    textAlign: "center",
    margin: "0 2rem 0",
    fontSize: "1.2rem",
  },
  description: {
    ...gStyles.fontRegular,
    color: colours.black,
    fontSize: ".9rem",
    lineHeight: "1.4rem",
    textAlign: "center",
    width: "100%",
    marginBottom: "1.5rem",
  },
};

// TODO: Share this (and a bunch of other stuff) with EpisodeCreatorsPrompt
const modalStyles = {
  closeButton: {
    top: 13,
    fontSize: ".9rem",
  },
};

const autoCompleteStyles = {
  searchContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
};

const searchStyles = {
  icon: {
    fontSize: "1.2em",
  },
  search: {
    border: "1px solid #DADADA",
  },
  input: {
    padding: ".8em 3em .7em 1.6em",
    height: "2.8rem",
  },
};

const desktopCTAStyles = {
  button: {
    border: "none",
    textDecoration: "underline",
    marginTop: ".75rem",
    ...gStyles.fontRegular,
    color: colours.black,
    fontSize: ".9rem",
    lineHeight: "1.4rem",
  },
};
const analyticsVariables = {
  action: "creatorSearch",
  context: "prompt",
  componentContext: "CreatorsPrompt",
};
const SEARCH_TYPES = ["creator"];

const CreatorsPrompt = (props) => {
  const { entity_type, title, entity, introText, onRequestClose } = props;
  const { styles } = useStyles(baseStyles, props);
  const { isWindowSizeOrLess } = useWindowSize();

  const searchInput = useRef(null);

  const addCreatorTo = useMemo(() => "/creators/add", []);

  const handleSearchFocus = useCallback(
    () =>
      scrollToComponent(searchInput.current, {
        align: "top",
        offset: -5,
      }),
    []
  );

  const resultClickOptions = useMemo(
    () => (onClose) => ({
      creator: (creator) => {
        const creatorSlug = getCreatorSlug(creator);
        if (entity_type === "podcast") {
          const tabUrl = getCreatorEditSelectEpisodesUrlByCreatorSlug(
            creatorSlug,
            entity.get("id")
          );

          window.open(tabUrl, "_blank");
        } else {
          const tabUrl = getCreatorEditSelectEpisodesUrlByCreatorSlug(
            creatorSlug,
            entity.get("podcast_id"),
            [entity.get("id")]
          );

          window.open(tabUrl, "_blank");
        }
        onClose();
      },
    }),
    [entity, entity_type]
  );

  const renderContent = (modalProps) => {
    const { onClose } = modalProps;
    const mobile = isWindowSizeOrLess("tiny");

    return (
      <div>
        <h3 className={css(styles.stageName)}>Search For A Creator</h3>
        <p className={css(styles.description)}>
          {introText ||
            (mobile
              ? `Help us out by adding the creators of this ${entity_type} below.`
              : `We don't know anything about the creators of this ${entity_type}. You can help us out by adding them below.`)}
        </p>
        <div className={css(styles.container)}>
          <div className={css(styles.search)}>
            <EntityNavigateSearch
              analyticsVariables={analyticsVariables}
              types={SEARCH_TYPES}
              searchKey="creator_banner_search"
              placeholder={
                mobile ? "Search creators..." : "Search for a creator to add..."
              }
              noResultsMessage="No creators by that name were found"
              searchMessage="Searching creators..."
              autoCompleteStyles={autoCompleteStyles}
              searchStyles={searchStyles}
              ref={searchInput}
              onFocus={handleSearchFocus}
              onResultClickOptions={resultClickOptions(onClose)}
              noFloatingResults
              hideHeading
              showViewLink
              white
              hideViewAll
            />
          </div>
          <div className={css(styles.addButton)}>
            {mobile ? (
              <StandardButton
                onClick={onClose}
                label={"Add Creator Profile"}
                variation="white"
                href={addCreatorTo}
                fontSize={mobile ? ".9rem" : "1.3rem"}
                height="2.8rem"
                width={mobile ? null : "2.8rem"}
                title="Add New Creator"
                target="_blank"
                anchor
                flat
              />
            ) : (
              <StandardButton
                onClick={onClose}
                label={"Add A New Creator"}
                href={addCreatorTo}
                title="Add New Creator"
                target="_blank"
                anchor
                variation="clear"
                customStyles={desktopCTAStyles}
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <GenericModal
      title={title || "No Creators"}
      renderContent={renderContent}
      styles={modalStyles}
      onClose={onRequestClose}
      smallTitle
    />
  );
};

CreatorsPrompt.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
  entity_type: PropTypes.string,
  entity: PropTypes.instanceOf(Map),
  introText: PropTypes.node,
  title: PropTypes.node,
};

CreatorsPrompt.defaultProps = {
  entity_type: "podcast",
  entity: Map({}),
  introText: null,
  title: null,
};

export default memo(CreatorsPrompt);
