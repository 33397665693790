import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { memo, useCallback } from "react";

import StandardButton from "components/Buttons/StandardButton";

import stageStyles from "./stageStyles";

import { useStyles } from "hooks/useStyles";

const ExistingCreatorAddCreditStage = (props) => {
  const {
    entity,
    creator,
    setStage,
    onSaveExistingGuestAppearance,
    saving,
    savingError,
    addAnotherRoleTo,
    onRequestClose,
  } = props;
  const { styles } = useStyles(stageStyles, props);

  const handleBack = useCallback(
    (e) => {
      e.preventDefault();
      setStage(null);
    },
    [setStage]
  );

  return (
    <div className={css(styles.stageContainer)}>
      <h3 className={css(styles.stageName)}>
        {`Add ${creator.get("name")} As A Guest`}
      </h3>
      <p className={css(styles.description)}>
        Just click below to save {creator.get("name")} as a guest on{" "}
        {`"${entity.get("title")}"`}.
      </p>
      <div className={css(styles.savingError)}>{savingError}</div>
      <a
        className={css(styles.addDifferentRoleLink)}
        onClick={onRequestClose}
        href={addAnotherRoleTo}
      >
        Not a guest? Add a different role
      </a>
      <div className={css(styles.buttonRow)}>
        <div className={css(styles.buttonContainer)}>
          <StandardButton
            label="Back"
            disabled={saving}
            variation="white"
            onClick={handleBack}
            flat
          />
        </div>
        <div className={css(styles.buttonDivider)} />
        <div className={css(styles.buttonContainer)}>
          <StandardButton
            label="Add Guest Credit"
            submitting={saving}
            variation="pink"
            onClick={onSaveExistingGuestAppearance}
            flat
          />
        </div>
      </div>
    </div>
  );
};

ExistingCreatorAddCreditStage.propTypes = {
  entity: PropTypes.instanceOf(Map).isRequired,
  creator: PropTypes.instanceOf(Map).isRequired,
  setStage: PropTypes.func.isRequired,
  onSaveExistingGuestAppearance: PropTypes.func.isRequired,
  saving: PropTypes.bool,
  savingError: PropTypes.node,
  addAnotherRoleTo: PropTypes.string.isRequired,
  onRequestClose: PropTypes.func.isRequired,
};

ExistingCreatorAddCreditStage.defaultProps = {
  saving: false,
  savingError: null,
};

export default memo(ExistingCreatorAddCreditStage);
